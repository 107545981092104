import {Component, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from '../common/service/api.service';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  standalone: true,
  templateUrl: 'gpt-test.component.html',
  styleUrls: ['gpt-test.component.scss'],
  imports: [CommonModule, MatButtonModule, FormsModule, MatInput, MatFormFieldModule]
})
export class GptTestComponent {
  url = 'https://news.mongabay.com/2024/04/deforestation-alerts-in-the-brazilian-amazon-fall-to-a-5-year-low/';
  result?: any;
  private api = inject(ApiService);

  parse = async () => {
    this.result = await this.api.adminTesting(this.url);
  };
}
